.mobileNavContainer{
  background-size: 100% 70px;
  display: flex;
  z-index: 997;
}

.mobile-nav-icon {
  //opacity:0.6;
  //position: relative;
}
.mobile-nav-icon-opaque {
  //opacity:1
}
@media (min-width: 768px) {
    .mobileNavContainer{
      display: none;
    }
  }