@import '../../assets/variables.scss';


.notes-container {

    //background-color: rgba(2, 174, 183, 0.5);
    align-items: flex-start;
    justify-content: center;
    //height: 100%;
    //overflow: scroll;
    //padding-bottom: 5 * $verticalMargin;
    //margin-top: $verticalSpacing;
}

textarea:focus {
    border-style: none;
    outline: 0;
    box-shadow: none;
}

.fileName {
    color: #e3e3e3; //lightgrey
    font-family: Nunito;
    font-size: 40px;
    padding-top: 25px //theme vertical spacing
}

.save-btn {
    margin: 0px;
    padding: 0px;
    background-color: transparent;
    border-style: none;
    align-self: flex-end;
    width: 30px;
    height: 30px;
}

.save-btn img {
    width: 30px;
    height: 30px;
    resize-mode: stretch;
    align-self: flex-end;
}

.notes-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.save-reminder {
    //globalStyles.bodyText
    font-size: 16px;
    font-family: $fontStack;
    color: $white;
    margin-top: $verticalSpacing;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-right: 15px;
}