@import "../../assets/variables.scss";

/*.pageContainer{
    left: 0px,
  }

@media (min-width: 768px) {
    .pageContainer{
      left: 20%,
    }
  }
*/
.login-layout-container{
  background-color: rgba(2, 174, 183, 0.5);
  max-width: 100%;
  height: 100%;
  display: 'flex';
  flex-direction: 'column';
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0px 10% 0 10%;
  overflow: scroll;
}
.login-container {
  padding-bottom: $verticalSpacing;
}
.login-logo {
  max-width: 100%;
  justify-self: flex-end;
  align-self: flex-end;
  margin-bottom: $verticalSpacing;
  margin-top: $verticalSpacing;
  width: 100px;
}
.login-layout-container .form-text-input, .login-layout-container .form-password-input {
  color: $white;
}
