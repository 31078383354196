/*.footerContainer{
    left: 0px,
  }

@media (min-width: 768px) {
    .footerContainer{
      left: 20%,
    }
  }
  */

  .footer-container{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    //background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 0.3));
    background-position: center;
    background-size: fixed;
    background-repeat: repeat;
    //borderStyle: 'solid',
    //borderColor: 'purple',
    //boderWidth: 1,
    position: fixed;
    bottom: 0px;
    right: 0px;
    left:0px;
  }