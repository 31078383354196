@import "../../assets/variables.scss";

.view-password {
    border-width: 0px;
    background-color: rgba(0, 0, 0, 0);
}

.dark-button {
    color: $white !important;
    background-color: $naomiBlue !important;
    border-color: $naomiBlue !important;
}