@import "../../assets/variables.scss";


li {
    border-bottom-width: 2px;
    border-bottom-color: rgb(0,0,0, .2);
    border-bottom-style: solid;
    font-family: Nunito;
    font-weight: 900;
    color: $white;
    align-self: center;
    //border-width: 1px;
    //border-color: $white;
    //border-style: solid;
    height: #{$verticalSpacing*1.5};
    width: 50%;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}
li a{
    text-decoration: none;
    text-transform: uppercase;
    align-self: center;
    color: $white;
}
li a:visited {
    color: $white;
}
.navContainer {
    display: none;
}
.logo{
    color: $white;
    text-align: center;
    font-family: $fontStack;
    font-size:22px
}
@media (min-width: 768px) {
    .navContainer{
      display: flex;
    }
  }