@import '../../assets/variables.scss'; 

.audio-header {
    background: url("../../assets/images/GreyTextureBackground.png") repeat fixed center; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    border-bottom: 2px solid rgb(2, 174, 183);
    position: fixed;
    top: 0px;
    //flex-grow: 1;
    //width: 100%;
    //padding-left: 15px;
    //padding-right: 15px;
    //margin-left: 15px;
    right: 15px;
    left: 15px;
    //z-index: 89;
}

.audio-header-title {
    font-size: 18px;
    font-family: $fontStack;
    font-weight: 900;
    color: $naomiBlue;
    text-transform: uppercase;
    margin-left: 35px;
}

.audio-header-icon {
  color: $naomiBlue;
  height: 25px;
  width: 25px;
  margin-right: $horizontalMargin;
}

.audio-list {
  //padding-top: 100px;
  //margin-bottom: 70px;
  //backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.2) 80%, rgba(0, 0, 0, 0.2))',
  //max-width: 100%;
  //overflow: hidden;
  flex:1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: $padding;
  margin-right:$padding;
  padding-top: 72 + $padding;

  //align-items: center;
}

.empty-audio-list {
    font-size: 22px;
    line-height: 25px;
    color: $white;
    margin-bottom: $padding;
    width: 100%;
    text-align: center;
    margin-top: $verticalSpacing;
}

.audio-loader {
  width: 100%;
  left: 0px;
}

@media (min-width: 768px) {
  .audio-header{
    top: $headerHeight;
  }

  .audio-header-title {

    margin-left: 70px;
  }
}