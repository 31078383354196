@import "../../assets/variables.scss";

.playerControl {
  background-color: transparent;
  border-style: none;
}

.playerContainer {
  margin-bottom: 0px;
  z-index: 998;
}

.player-toolbar {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}

.player-tool {
  background-color: transparent;
  border-style: none;
  height: 23px;
  margin-left: $margin
}

.player-tool-icon {
  color: $white;
  background-color: rgba($color: #000000, $alpha: 0.0);
  color: rgba($color: $naomiBlue, $alpha: 0.6);
  font-size: 20px,
}

.player-tool-icon-active {
  color: $naomiBlue;
}

@media (min-width: 768px) {
  .playerContainer {
    padding-bottom: 15px
  }
}

.tool-container{
  bottom: $mobileFooterHeight;
  overflow: scroll;
  transition: height 1s ease-in-out;
  position: fixed;
  top: 0px;
  left: 0px;
  //right: 0px;
  width:100%;
  height:0px;
  z-index: 999;
  opacity: 1;
}
.looper-input {
  //width:33%
  color: $white;
  max-width:100px;
}
.looper-input::placeholder {
  color: $white;
}

.looper-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: $white;
  margin-bottom: $margin;
}

.playback-input {
  //width:33%
  color: $white;
}
.playback-input::placeholder {
  color: $white;
}

.tool-spacer {
  display: flex;
  background-color: $naomiBlue;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  padding: $padding;
  position: relative;
}

.tool-inner-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 400px;
  margin-bottom: $verticalSpacing;
}

.tool-control-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: $verticalSpacing;
}

.tool-control-group label {
  font-size: 18px;
  font-family: Nunito;
  font-weight: 900;
  color: $white;
  padding-bottom: $padding;
  text-transform: uppercase;
}

.tool-title {
  font-family: Nunito;
  font-size: 48px;
  color: $white;
  margin-bottom: $verticalSpacing;
  padding-top: $padding;
}

.looper-button{
  max-width: 150px;
  margin-bottom: $margin;
  border-style: none;
  border-radius: 6px;
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
}

.looper-button-group{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width:100%;
  
}

@media (min-width: 768px) {
  .tool-spacer {
    padding-top: $headerHeight;
  }
  .tool-container{
    bottom: $footerHeight;
  }
}
