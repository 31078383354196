/*html, body, #root{
  //width: 100%;
  height: 100%;
  //min-height:100%;
}*/
html {
  min-height: 100%;
  display: flex;
}

body  {
  flex: 1;
  display: flex;
}
#root {
  flex:1
}
.App {
  background: url("./assets/images/GreyTextureBackground.png") repeat fixed center; 
  min-width: 100%;
  min-height: 100%;
  position: relative;
  height: 100%;

}
 
