
@import "../../assets/variables.scss";

.page-container {
  //border-width: 1px;
  //border-style: solid;
  //border-color: transparent;
  position: absolute;
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  overflow: scroll;
  //padding-top: 2*$padding;
  padding-bottom: $mobileFooterHeight;
  padding-left:$padding;
  padding-right:$padding;
  //width: 100%
}

@media (min-width: 768px) {
  .page-container{
    padding-top: $headerHeight;
    padding-bottom: $footerHeight;
  }
}