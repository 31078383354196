@import './variables.scss';

.container {
    flex: 1;
    padding-left: horizontalMargin;
    padding-right: horizontalMargin;
}
.form {
    max-width: 500px;
    margin: auto;
}

.form-group {
    margin-bottom: $verticalSpacing
}

.form-text-input {
    font-size: 22px;
    //line-height: 24;
    font-family: $fontStack;
    //padding: theme.inputPadding,
    background-color: rgba(0, 0, 0, 0);
    border-width: 0px;
    border-bottom-width: 1px;
    border-bottom-color: $white;
    width: 100%;
    box-shadow: 'none';
    border-style:solid
}

.form-password-input {
    @extend .form-text-input;
    border-bottom-width: 0px;
}
.form-password-input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-width: 0px;
    border-bottom-width: 1px;
    border-bottom-color: $white;
    border-style:solid;
}
.paragraph {
    font-size: 22px;
    //line-height: 24;
    font-family: $fontStack;
    //padding: theme.inputPadding,
    margin-bottom: $verticalSpacing
}

.form-text-input-error {
    border-bottom-color: $red
}

.form-text-input:focus {
    border-width: 0px;
    border-bottom-width: 1px;
    border-bottom-color: $white;
}

.form-label {
    font-size: 30px;
    //borderWidth:1,
    //borderColor: "red",
    //height: 100,
    margin-top: 10px;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 6px;
    font-family: $fontStack;
}

.form-validation-error {
    color: $red;
    text-align: "left";
    height: 20;
    font-family: $fontStack;
    align-self: "flex-start"
}

.center {
    text-align: center;
}

.vertical-spacing {
    margin-bottom: $verticalSpacing
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding: $btnPadding;
    background-color: $white;
    margin-bottom: $verticalSpacing;
    width: 100%;
    height: 30px;
    elevation: 3px;
    text-transform: uppercase;
    font-family: $fontStack;
    color: $naomiBlue;
    font-size: 24px;
    cursor: pointer

  }
.naomi-blue {
    color: $naomiBlue
}

.link {
    text-decoration: none;
}
.nav-link-dark, .nav-link-dark:visited{
    color: $naomiBlue
}
.nav-link-dark:hover{
    color: $naomiBlueDark;
}
.nav-link-light, .nav-link-light:visited{
    color: $white
}
.nav-link-light:hover{
    //color: $white
}

textarea{
    //background-color: rgba(255,255,255, 0.5);
    background-color: rgba(2,174,183, 0.7);
    border-radius: 10px;
    border-style: none;
    height: 300px;
    font-family: $fontStack;
    text-align: top;
    padding: $padding;
    font-size: 16px;
    margin-bottom: $verticalSpacing / 2;
    width: 100%;
    box-sizing: border-box;
    margin-top: $verticalSpacing / 2;
    color:$white
}

.opacity{
    opacity: 1
}

.title{
    font-family: $fontStack;
    font-size: 48px;
    //color: $naomiBlue;
    color: $white;
    margin-bottom: $verticalSpacing;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translatey(-50%);
    transform: translatey(-50%);

  }
  .horizontal-center  {
    display:flex;
    align-items: center;
    flex-direction: row;
  }

  .modal {
    display: flex;
    flex: 1;
    width: 66%;
    //height: 80%;
    max-height: 80%;
    position: fixed;
    top: 2 * $verticalSpacing;
    background-color: $naomiBlue;
    //min-height: (theme.height * .8);
    //margin-horizontal: 17%;
    //shadow-color: theme.black;
    //shadow-offset: { width: 0, height: 0 };
    //shadow-opacity: 0.35;
    //shadow-radius: 10;
    //elevation: 2;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    //background-color: theme.white;
    //padding-horizontal: 75;
    //padding-vertical: 45;
  }
  .modal-text {
    //color: theme.black;
    font-size: 25;
    line-height: 30;
   // margin-bottom: (theme.verticalSpacing * .25);
    //fontFamily: "SourceSansPro_400Regular",
  }
  .modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    //background-color: theme.offWhite;
    //padding-horizontal: theme.verticalSpacing;
    //padding-vertical: theme.verticalSpacing;
  }
  .modal-bg {
    flex: 1;
    //max-height: theme.height;
    justify-content: flex-end;
    bottom: 10%;
  }