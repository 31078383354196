@import "../../assets/variables.scss";

.view-password {
    border-width: 0px;
    background-color: rgba(0, 0, 0, 0);
}

.dark-input{
    color: $white
}
.dark-label{
    color: $naomiBlue !important
}