@import "../../assets/variables.scss";


.header-container {

    display: none;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    //borderColor: orange;
    //borderWidth: 1;
    height: 45px;
    background-color: $naomiBlue;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px;
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 98;
    //borderWidth: 1; borderColor: 'blue',
}

.account-popup{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: fixed;
    width: 300px;
    //height: 400px;
    top:55px;
    right:0px;
    background-color: $white;
    z-index: 99;
    padding: 15px;
    font-family: $fontStack;
    color: $naomiBlue;
}
.my-account-popup-item{
    margin-bottom: $verticalMargin;
}
.toggle-controls-container{
    display: none
}
.btn-profile-popup-logout{
    //background-color: $naomiBlue;
    background-color: $accentColor;
    margin-bottom: $verticalSpacing;
    width: 100%;
    height: 30px;
    elevation: 3;
    max-width: 400px;
    border-style: none;
    border-radius: 6px;
    text-align: center;
    text-transform: uppercase;
    color: $white;
    font-family: $fontStack;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.my-account-popup-hr {
    background-color: transparent;
    width: 100%;
    border-top: dotted 1px $accentColor;
    border-bottom-width: 0px;
}
@media (min-width: 768px) {
    .header-container {
        display: flex;
    }
    .toggle-controls-container{
        display: block;
    }
}
