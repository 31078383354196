@import '../../assets/variables.scss';

.profile-container {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    //background-color: rgba(2, 174, 183, 0.5);
    align-items: center;
   // justify-content: center;
    //padding-left: 20px;
    //padding-right: 20px;
    overflow: scroll;
    //padding-bottom: 230px;
    //margin-top: $padding;
}

.profile-form {
    -webkit-overflow-scrolling: touch;
    //overflow-y: scroll;
    width: 100%;
    margin: auto;
    //margin-top: 2 * $verticalSpacing;
    max-width: 500px,
}

.profile-form .form-text-input {
    color: $white
}

.profile-pic {
    align-self: flex-start;
    //width: 100%,
    resize-mode: contain;
    //margin-top: $verticalSpacing;
    margin-bottom: $verticalSpacing;
    height: 135px;
    width: 116px;
    opacity: .8;
}

.btn-profile-logout {
    background-color: $white;
    margin-bottom: $verticalSpacing;
    width: 100%;
    height: 30px;
    elevation: 3;
    //max-width: 400px;
    border-style: none;
    border-radius: 6px;
    text-align: center;
    text-transform: uppercase;
    font-family: Nunito;
    color: $naomiBlue;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reset-password {
    font-size: 22px;
    font-family: $fontStack;
    cursor: pointer;
    color: $naomiBlue;
    margin-bottom: $verticalSpacing;
}

.profile-title{
    margin-top: $padding;
 }

.profile-label{
    color:$naomiBlue;
}

@media (min-width: 768px) {
    .profile-title{
       margin-top: $padding*2;
    }
}