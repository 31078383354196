@import "../../assets/variables.scss";



.upload-container{
  display:flex;
  flex-direction: column;
  align-items: center;
  //justify-content: ;
  margin: auto;
  width: 100%;
  font-family: $fontStack;
  font-weight: 900;
  color: white;
  overflow: scroll;
  margin-top:$margin;

}
.Dropzone {

    width: 100%;
    //background-color: transparent;
    //border: 2px dashed rgba(2, 174, 183, 1);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%2302AEB7FF' stroke-width='5' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 16px;
    /*border-radius: 50%;*/
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    color: white;
    height: 250px;
    //margin-top: 40%;
  }
  .Dropzone span{ display: none; }
  #dropzone-container{
    margin-bottom: 15px;
    max-width: 400px;
    //margin-right: $padding;
    //margin-left: $padding;

  }
  .Icon {
    //margin-top: 15px;
    width: 40%;
  }
  .DropZoneText{
    //margin-bottom: 15px;
    width: 40%;
    text-align: center;
  }
  
  .FileInput {
    display: none;
  }
  .Highlight {
    background-color: rgba(2, 174, 183, .2);
    //border-style: solid;
  }

  .CheckIcon {
    opacity: 0.5;

  }
  .CheckIcon, .XOut {
    height: 15px;
  }
  .ProgressWrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
  }
  .Actions button {
    /*font-family: 'Roboto medium', sans-serif;*/
    font-size: 14px;
    font-weight: 700;
    display: inline-block;
    height: 36px;
    min-width: 88px;
    padding: 6px 16px;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    border-radius: 2px;
    color: #fff;
    outline: 0;
    flex: 1;
    margin-bottom:15px;
    background: rgba(2, 174, 183, 1);
  }
  
  button:disabled {
    background: rgb(189, 189, 189);
    cursor: default;
    color: #fff;
  }

  button {
    cursor: default;
  }

  .Upload {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
  }
  .Files {
    align-items: flex-start;
    justify-items: flex-start;
    flex: 1;
    margin-bottom: $margin*2;

  }
  .Files .row{
    margin-left: 0px;
    margin-right: 0px;
  }
  .Actions {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: flex-end;
    flex-direction: row;
    margin-top: 32px;
  }
  
  .Actions .upload:disabled{
    color: #fff;;
  }

  .Actions .upload{
    margin-right: 10px;
    color: #171717;
  }

  .Actions .clear{
    margin-left: 10px;
  }



  .Filename {
    margin-bottom: 8px;
    font-size: 16px;
    color: white;
    margin-left: 5px;
  }
  
  #date-picker-container{
    display: none;
  }
  /*.Row {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 50px;
    padding: 8px;
    overflow: hidden;
    box-sizing: border-box;
  }*/

  .ProgressBar {
    width: 100%;
    height: 8px;
    background-color: rgba(2, 174, 183, .2);
    border-radius: 5px;
  }
  
  .Progress {
    background-color: rgba(2, 174, 183, 1);
    height: 100%;
    margin: 0;
    border-radius: 5px;
  }

  @media (min-width: 768px) {
    .upload-container{
        margin-top: $margin*2;
    }
}