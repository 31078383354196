@import '../../assets/variables.scss'; 

.forgot-password {
    font-size: 22px;
    font-family: $fontStack;
    cursor: pointer;
}

.view-password {
    border-width: 0px;
    background-color: rgba(0, 0, 0, 0);
}

.password-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.form-password-input {
    border-width: 0px;
    width: 100%;
}