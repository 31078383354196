/* nunito-regular - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/nunito-v20-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('./fonts/nunito-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./fonts/nunito-v20-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('./fonts/nunito-v20-latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('./fonts/nunito-v20-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('./fonts/nunito-v20-latin-regular.svg#Nunito') format('svg');
    /* Legacy iOS */
}

$fontStack: Nunito;
$naomiBlue: #02aeb7;
//$naomiBlue: #00b4c1;
$accentColor: #995eff;
//$accentColor: #db7c0b;
$red: #e41e2e;
$white: #ffffff;
$verticalSpacing: 50px;
$margin: 15px;
$verticalMargin: $margin;
$horizontalMargin: $margin;
$padding: 15px;
$btnPadding: 20px;
$naomiBlueDark: hsl(183, 96%, 22%);
$mobileFooterHeight: 237px;
$footerHeight: 182px;
$headerHeight:55px;