@import "../../../assets/variables.scss";

.audio-item-hr {
  display: block;
  background: linear-gradient(to right, red, yellow);
  height: 2px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(255, 255, 255, 0.4) 35%, rgba(255, 255, 255, 0.4) 65%, rgba(0, 0, 0, 1));
  border-style: none;
}

.HR {
  display: none;
}

.listing-item {
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  //paddingLeft: 10;
  //paddingRight: 10;
  padding-top: 5px;
  padding-bottom: 5px;
  //backgroundColor: theme.offWhite;
}

.equalizer {
  //borderWidth: 1;
  //borderColor: blue;
  height: 20px;
  //width: 30px;
}

.equalizer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 35px;
  justify-content: flex-start;
}

.title-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  //marginLeft: 15px;
  flex-wrap: nowrap;
  //flexGrow: 1
  font-size: 14px;

}
.title-container h4 {

  margin:0px;
  font-size: 14px;
  /*
  background: linear-gradient(to right,
     rgba(255,255,255, 1) 20%, 
     rgba(255,255,255, 0) 80%
  );
  //background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  flex-grow: 1;
  */
}

.note-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 10%;
  justify-content: flex-end;
  padding-left: 10px;
  //borderWidth: 1,
  //borderColor: "orange",
  //borderStyle: "solid"
}

.time-container {
  font-size: 12px;
  font-family: $fontStack;
  color: $white;
  //marginRight: 15,
  //marginLeft: 15
  display: flex;
  flex-direction: row;
  align-items: center;
  //width: 20%;
  min-width: 50px;
  justify-content: flex-end;
  //paddingRight: 15
}

.file-controls {
  display: flex;
  background-color: $naomiBlue;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  justify-content: flex-start;
  padding: $padding
}

.btn-file-control{
  background-color: $white;
  //margin-top: $verticalSpacing;
  width: 100%;
  height: 30px;
  elevation: 3;
  max-width: 400px;
  border-style: none;
  border-radius: 6px;
  text-align: center;
  text-transform: uppercase;
  color: $naomiBlue;
  font-family: $fontStack;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

.control-icon {
  display: inline-block;
}
@media (min-width: 768px) {
//@media (min-width: 1px) {
  .equalizer-container {
    width: 70px;
    //justify-content: flex-start;
  }

  .time-container {
    width: 80px;
    justify-content: flex-end;
  }

  .note-container {
    width: 30px;
    //justify-content: flex-end;
  }

  /*.title-container {
    flex-grow: 1
  }*/

  /*.HRMobile {
    display: none;
  }

  .HR {
    display: block;
  }*/

}