@import "../../../assets/variables.scss";

.account-reset-password-container {
    max-width: 500px;
    margin: auto;
    //margin-top: 100px;
    // margin-bottom: 100px;
    //margin-left: $padding;
    //margin-right: $padding;
    margin-top: $padding;
}

.dark-title {}

@media (min-width: 768px) {
    .account-reset-password-container {
        margin-top: $padding*2;
    }

}